import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure axios is installed
import "./NotificationMessagesPage.css";
import config from "../../../config/config";

const NotificationMessagesPage = () => {
  const [messages, setMessages] = useState([]);

  // Fetch messages from the Laravel API when the component mounts
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/message-list`);
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  const markAsRead = async (id) => {
    try {
      await axios.patch(`http://localhost/api/message-list/${id}`, {
        read: true,
      });
      setMessages((prev) =>
        prev.map((message) =>
          message.id === id ? { ...message, read: true } : message
        )
      );
    } catch (error) {
      console.error("Error marking as read:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    }).format(date);
  };

  return (
    <div className="notification-messages-page">
      <h1>Notification Messages</h1>
      <table className="messages-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => (
            <tr key={message.id} className={message.read ? "read" : "unread"}>
              <td>{message.name}</td>
              <td>{message.email}</td>
              <td>{message.phone || "N/A"}</td>
              <td>{message.message || "No message"}</td>
              <td>{formatDate(message.created_at)}</td>{" "}
              {/* Display formatted date */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NotificationMessagesPage;
