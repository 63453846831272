import React, { useState } from "react";
import PagesHero from "../../../components/client/common/PagesHero/PagesHero";
import { FaPhone } from "react-icons/fa";
import { BsEnvelope, BsWhatsapp } from "react-icons/bs";
import axios from "axios";
import config from "../../../config/config";

import "./ContactPage.css";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [feedback, setFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState(""); // "success" or "error"
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }

    // Only validate message if it's not empty
    if (formData.message.trim() && !formData.message.trim()) {
      newErrors.message = "Message is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      setFeedback("");
      setFeedbackType(""); // Reset feedback type

      try {
        const response = await axios.post(
          `${config.API_URL}/send-message`,
          formData
        );

        if (response.data.status === 1) {
          setFeedback("Your message has been sent successfully!");
          setFeedbackType("success"); // Set feedback type as success
          setFormData({ name: "", email: "", phoneNumber: "", message: "" });
        } else {
          setFeedback(
            "There was an issue sending your message. Please try again later."
          );
          setFeedbackType("error"); // Set feedback type as error
        }
      } catch (error) {
        setFeedback(
          "Failed to send message. Please check your internet connection."
        );
        setFeedbackType("error"); // Set feedback type as error
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setFeedback("Please fill in all required fields.");
      setFeedbackType("error"); // Set feedback type as error
    }
  };

  return (
    <div className="page-container">
      <PagesHero
        title="Contact Us"
        subtitle="Let’s Start Your Digital Transformation Journey"
      />
      <section className="contact-content">
        <div className="contact-section">
          <h2>We’d Love to Hear From You!</h2>
          <p>
            Got a question or an idea you want to explore? Our team is excited
            to help you turn your vision into reality. Reach out to us and let’s
            start the conversation!
          </p>
        </div>

        <div className="contact-section contact-message">
          <div className="getin-touch">
            <h2 className="section-heading">Get in Touch</h2>

            <ul className="contact-info">
              <li className="email">
                <BsEnvelope />
                <a href="mailto:info@theinfines.com">info@theinfines.com</a>
              </li>
              <li className="call">
                <FaPhone />
                <a href="tel:+919633898785">+91 96338 98785</a>
              </li>
              <li className="whatsapp">
                <BsWhatsapp />
                <a
                  href="https://wa.me/+919633898785"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Connect through Whatsapp
                </a>
              </li>
            </ul>
          </div>
          <div className="message-us">
            <div className="contact-section">
              <div className="form-container">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">
                      Name<span className="important-star"> *</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="important-star"> *</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && (
                      <span className="error">{errors.message}</span>
                    )}
                  </div>

                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </form>

                {feedback && (
                  <span
                    className={`feedback ${
                      feedbackType === "success" ? "success" : "error"
                    }`}
                  >
                    {feedback}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
